<template>
  <section>
    <!-- Main Content Wrapper -->
    <div class="page-content-lightestblue">
      <div v-if="brands" class="page-content-lightestblue__container">
        <div class="heading-main py-4">
          <h2>Prämien nach Marken</h2>
        </div>
        <div class="content flex flex-row flex-wrap h-full justify-center">
          <div v-for="brand in brands" :key="brand">
            <div class="m-4">
              <a :title="`${brand?.brand_name}`" @click="redirectToHome(`${brand?.brand_name}`)">
                <app-image
                  v-if="checkIfFileExist(brand?.image)"
                  :title="`${brand?.brand_name}`"
                  :alt="`${brand?.brand_name}`"
                  :src="brand.image"
                ></app-image>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { computed } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import AppImage from "@/components/Image/Image";

  export default {
    name: "Brands",
    components: {
      AppImage,
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      store.dispatch("catalog/getBrands");

      const redirectToHome = (params) => {
        router.push({
          name: "default",
          query: { q: params },
        });
      };

      const checkIfFileExist = (brandImage) => {
        try {
          const brands = brandImage;
         
          if (brands) {
            return brands;
          }
        } catch (e) {
          return "";
        }
      };

      return {
        brands: computed(() => {
          return store.getters["catalog/getBrands"];
        }),
        redirectToHome,
        checkIfFileExist,
      };
    },
  };
</script>
